import {
    BoxEdges,
    Button,
    Grid,
    Paragraph,
    Separator,
    Spacer,
    Survey,
} from "@clearhaus/design-system"
import { HELPDESK_SURVEY_ID } from "modules/customer-feedback/constants"
import { SurveyAccepted } from "modules/insights/Events/SurveyAccepted"
import React from "react"
import { Translate } from "react-localize-redux"

interface IIntroductionStepProps {
    onClick: () => void
}

export const IntroductionStep: React.FC<IIntroductionStepProps> = (props) => {
    const { onClick } = props

    const handleOnClick = () => {
        if (HELPDESK_SURVEY_ID) {
            SurveyAccepted.log({ id: HELPDESK_SURVEY_ID })
        }

        onClick()
    }

    return (
        <>
            <Grid row alignContent="center" noGutters>
                <Grid item auto>
                    <BoxEdges mr={12}>
                        <Spacer size={9} />
                        <Survey.PulseDot />
                    </BoxEdges>
                </Grid>
                <Grid item>
                    <Survey.Title>
                        <Translate id="surveys.helpdesk_survey.introduction.title" />
                    </Survey.Title>
                </Grid>
            </Grid>
            <BoxEdges mt={12} mb={16}>
                <Separator />
            </BoxEdges>
            <Survey.Body>
                <Paragraph>
                    <Translate id="surveys.helpdesk_survey.introduction.description" />
                </Paragraph>
                <Spacer size={24} />
                <Grid row noGutters>
                    <Grid item auto>
                        <Survey.Close>
                            <Button color="primary" light size="normal">
                                <Translate id="surveys.helpdesk_survey.introduction.close_button" />
                            </Button>
                        </Survey.Close>
                    </Grid>
                    <Spacer size={16} />
                    <Grid item>
                        <Button size="normal" onClick={handleOnClick}>
                            <Translate id="surveys.helpdesk_survey.introduction.next_button" />
                        </Button>
                    </Grid>
                </Grid>
            </Survey.Body>
        </>
    )
}
