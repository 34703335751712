import * as RadixToast from "@radix-ui/react-toast"
import React, { useContext } from "react"
import { BoxEdges } from "../BoxEdges"
import { Card } from "../Card"
import { Paragraph } from "../Paragraph"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

const useStyles = makeStyles(styles, "Survey", true)

interface ISurveyProviderProps {
    children: React.ReactNode
}

const SurveyProvider: React.FC<ISurveyProviderProps> = (props) => {
    const { children } = props

    return <RadixToast.Provider swipeThreshold={50000}>{children}</RadixToast.Provider>
}

const SurveyViewport: React.FC = () => {
    const classes = useStyles()
    return <RadixToast.Viewport className={classes.viewport} />
}

interface ISurveyProps {
    children: React.ReactNode
    open: boolean
    onOpenChange: (open: boolean) => void
    duration?: number
    attentionAnimation?: "shake" | "none"
}

const Survey = (props: ISurveyProps) => {
    const { children, open, onOpenChange, duration = 60000, attentionAnimation = "none" } = props

    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()

    const [paused, setPaused] = React.useState(false)

    const onPause = () => {
        setPaused(true)
    }

    const onResume = () => {
        setPaused(false)
    }

    return (
        <RadixToast.Root
            key={duration}
            open={open}
            duration={duration}
            className={classes.root}
            onOpenChange={onOpenChange}
            onPause={onPause}
            onResume={onResume}
            data-paused={paused}
            data-attention-animation={attentionAnimation}
        >
            <BoxEdges shadowCustom={defaults.shadows.large.wide} cornerRadius={6}>
                <Card flatten>
                    <Card.Content noSpacing>
                        <BoxEdges p={24}>{children}</BoxEdges>
                    </Card.Content>
                </Card>
            </BoxEdges>
        </RadixToast.Root>
    )
}

interface ITitleProps {
    children: React.ReactNode
}

const Title: React.FC<ITitleProps> = (props) => {
    return (
        <RadixToast.Title>
            <Paragraph weight="medium">{props.children}</Paragraph>
        </RadixToast.Title>
    )
}

const PulseDot: React.FC = () => {
    const classes = useStyles()
    return <div className={classes.pulseDot} />
}

interface IBodyProps {
    children: React.ReactNode
}

const Body: React.FC<IBodyProps> = (props) => {
    return <RadixToast.Description>{props.children}</RadixToast.Description>
}

interface ICloseProps {
    children: React.ReactNode
}

const Close: React.FC<ICloseProps> = (props) => {
    return <RadixToast.Close asChild>{props.children}</RadixToast.Close>
}

Survey.Title = Title
Survey.PulseDot = PulseDot
Survey.Body = Body
Survey.Close = Close

export { Survey, SurveyProvider, SurveyViewport }
