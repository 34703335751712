import { EventBase } from "modules/insights/Events/EventBase"

interface SurveySubmittedParams {
    id: string
}

export class SurveySubmitted {
    static log<P extends SurveySubmittedParams>(params: P) {
        EventBase.log("survey_submitted", params)
    }
}
