import { Survey } from "@clearhaus/design-system"
import React, { useEffect, useState } from "react"
import { HelpdeskSurveySteps } from "./HelpDeskSurveySteps"

const HelpdeskSurvey: React.FC = () => {
    const [duration, setDuration] = useState(45000)
    const [open, setOpen] = useState(true)
    const [step, setStep] = useState(0)
    const [shouldAnimate, setShouldAnimate] = useState(true)

    const goNext = () => {
        setStep((prev) => prev + 1)
    }

    useEffect(() => {
        if (step === 1) {
            setOpen(false)
            requestAnimationFrame(() => {
                setDuration(Infinity)
                setOpen(true)
                setShouldAnimate(false)
            })
        }
        if (step === 2) {
            setOpen(false)
            requestAnimationFrame(() => {
                setDuration(5000)
                setOpen(true)
                setShouldAnimate(false)
            })
        }
    }, [step])

    const attentionAnimation = shouldAnimate ? "shake" : "none"

    return (
        <Survey
            duration={duration}
            open={open}
            onOpenChange={setOpen}
            attentionAnimation={attentionAnimation}
        >
            <HelpdeskSurveySteps step={step} goNext={goNext} />
        </Survey>
    )
}

export { HelpdeskSurvey }
