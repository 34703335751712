import * as RadixToggleGroup from "@radix-ui/react-toggle-group"
import React from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

const useStyles = makeStyles(styles, "ToggleGroup", true)

interface IToggleGroupProps {
    children: React.ReactNode
    onValueChange?: (value: string) => void
}

const ToggleGroup = (props: IToggleGroupProps) => {
    const { children, onValueChange } = props

    const classes = useStyles()

    return (
        <RadixToggleGroup.Root
            type="single"
            className={classes.group}
            onValueChange={onValueChange}
        >
            {children}
        </RadixToggleGroup.Root>
    )
}

interface IItemProps {
    children: React.ReactNode
    value: string
}

const Item: React.FC<IItemProps> = (props) => {
    const { children, value } = props

    const classes = useStyles()

    return (
        <RadixToggleGroup.Item value={value} className={classes.item}>
            {children}
        </RadixToggleGroup.Item>
    )
}

ToggleGroup.Item = Item

export { ToggleGroup }
