import { EventBase } from "modules/insights/Events/EventBase"

interface SurveyAcceptedParams {
    id: string
}

export class SurveyAccepted {
    static log<P extends SurveyAcceptedParams>(params: P) {
        EventBase.log("survey_accepted", params)
    }
}
