import { SurveyProvider } from "@clearhaus/design-system"
import { HelpdeskSurvey } from "components/surveys/surveys/helpdesk/HelpdeskSurvey"
import { HELPDESK_SURVEY_ID } from "modules/customer-feedback/constants"
import { SurveyShown } from "modules/insights/Events/SurveyShown"
import React, { useMemo } from "react"
import { SurveyLocalStorage } from "./helpers"

interface Survey {
    id?: string
    component: React.ReactElement
}

const AVAILABLE_SURVEYS: Survey[] = [
    {
        id: HELPDESK_SURVEY_ID,
        component: <HelpdeskSurvey />,
    },
]

interface SurveysContext {
    showSurvey: (surveyId?: string) => void
}

const defaultSurveysContext: SurveysContext = {
    showSurvey: () => {
        throw Error("showSurvey not overriden")
    },
}

const SurveysContext = React.createContext<SurveysContext>(defaultSurveysContext)

interface IProviderProps {
    children: React.ReactNode
}

const SurveysProvider: React.FC<IProviderProps> = (props) => {
    const { children } = props
    const [activeSurvey, setActiveSurvey] = React.useState<Survey | null>(null)
    const [surveyKey, setSurveyKey] = React.useState<number>(0)

    const showSurvey = (surveyId: string) => {
        const survey = AVAILABLE_SURVEYS.find((s) => s.id === surveyId)

        if (!survey) {
            return
        }

        const surveysLocalStorage = new SurveyLocalStorage()

        if (surveysLocalStorage.idExists(surveyId)) {
            return
        }

        SurveyShown.log({ id: surveyId })

        surveysLocalStorage.saveId(surveyId)

        setActiveSurvey(survey)
        setSurveyKey((prev) => prev + 1)
    }

    const contextValue = useMemo(() => ({ showSurvey }), [])

    return (
        <SurveyProvider>
            <SurveysContext.Provider value={contextValue}>
                {children}
                {activeSurvey?.component &&
                    React.cloneElement(activeSurvey.component, { key: surveyKey })}
            </SurveysContext.Provider>
        </SurveyProvider>
    )
}

export { SurveysProvider, SurveysContext }
