import {
    BoxEdges,
    Grid,
    Paragraph,
    Separator,
    Spacer,
    Survey,
    ThemeContext,
    ThumbsUpIcon,
} from "@clearhaus/design-system"
import React, { useContext } from "react"
import { Translate } from "react-localize-redux"

export const ConclusionStep: React.FC = () => {
    const { defaults } = useContext(ThemeContext)

    return (
        <>
            <Grid row alignContent="center" noGutters>
                <Grid item auto>
                    <BoxEdges mr={10}>
                        <Spacer size={3} />
                        <ThumbsUpIcon size={16} color={defaults.colors.primary[500]} />
                    </BoxEdges>
                </Grid>
                <Grid item>
                    <Survey.Title>
                        <Translate id="surveys.helpdesk_survey.conclusion.title" />
                    </Survey.Title>
                </Grid>
            </Grid>
            <BoxEdges my={12}>
                <Separator />
            </BoxEdges>
            <Survey.Body>
                <Paragraph>
                    <Translate id="surveys.helpdesk_survey.conclusion.description" />
                </Paragraph>
            </Survey.Body>
        </>
    )
}
