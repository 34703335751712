export class SurveyLocalStorage {
    private surveys: string[] = []

    constructor() {
        const lsSurveys = localStorage.getItem("surveys")
        this.surveys = lsSurveys ? JSON.parse(lsSurveys) : []
    }

    idExists(surveyId: string) {
        return this.surveys.includes(surveyId)
    }

    saveId(surveyId: string) {
        this.surveys.push(surveyId)
        localStorage.setItem("surveys", JSON.stringify(this.surveys))
    }
}
