import { EventBase } from "modules/insights/Events/EventBase"

interface SurveyShownParams {
    id: string
}

export class SurveyShown {
    static log<P extends SurveyShownParams>(params: P) {
        EventBase.log("survey_shown", params)
    }
}
