import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    group: {
        width: "100%",
        display: "flex",
        backgroundColor: defaults.colors.neutrals[400],
        borderColor: defaults.colors.neutrals[400],
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
    },

    item: {
        borderWidth: 0,
        backgroundColor: defaults.colors.neutrals[100],
        color: defaults.colors.neutrals[800],
        fontSize: defaults.fontSizes[14],
        fontWeight: defaults.fontWeights.medium,
        height: 36,
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        lineHeight: defaults.lineHeights.s,
        marginLeft: 1,
        outline: "none",
        userSelect: "none",

        "&:first-child": {
            marginLeft: 0,
            borderTopLeftRadius: defaults.cornerRadius.normal,
            borderBottomLeftRadius: defaults.cornerRadius.normal,
        },

        "&:last-child": {
            borderTopRightRadius: defaults.cornerRadius.normal,
            borderBottomRightRadius: defaults.cornerRadius.normal,
        },

        "&:hover": {
            backgroundColor: defaults.colors.primary[200],
        },

        '&[data-state="on"]': {
            color: defaults.colors.neutrals[100],
            backgroundColor: defaults.colors.primary[500],
        },
    },
})

export default styles
