export const ACTION_PREFIX = "@@me/"
export const GET_ME = "@@me/GET_ME"
export const GET_ME_OK = "@@me/GET_ME_OK"
export const GET_ME_ERROR = "@@me/GET_ME_ERROR"
export const UPDATE_ME = "@@me/UPDATE_ME"
export const UPDATE_ME_OK = "@@me/UPDATE_ME_OK"

export interface UpdateMeDto {
    name?: string
    phone?: string
    timezone?: string
    locale?: string
    status?: string
    blocked?: boolean
}
export interface UpdateMe extends UpdateMeDto {}

export interface MeDto extends UpdateMeDto {
    type: "user"
    email: string
    blocked_by_protection: boolean
    blocked_manually: boolean
}
export interface Me extends MeDto {}

export type MeState = Me | null
