import { fork, put, takeEvery } from "@redux-saga/core/effects"
import { updateMe } from "modules/me/actions"
import { setActiveLanguage } from "react-localize-redux"
import { ActionType } from "typesafe-actions"
import languageCodeToLocale from "utils/languageCodeToLocale"
import { SagaRegistry } from "utils/SagaRegistry"

function* handleSetActiveLanguage(action: ActionType<typeof setActiveLanguage>) {
    const locale = languageCodeToLocale(action.payload.languageCode)
    yield put(updateMe({ locale }))
}

function* watchSetActiveLanguage() {
    yield takeEvery("@@localize/SET_ACTIVE_LANGUAGE", handleSetActiveLanguage)
}

function* updateLocaleOnSetActivelanguageRootSaga() {
    yield fork(watchSetActiveLanguage)
}

SagaRegistry.register(updateLocaleOnSetActivelanguageRootSaga)
