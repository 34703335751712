import React from "react"
import { ConclusionStep } from "./steps/ConclusionStep"
import { IntroductionStep } from "./steps/IntroductionStep"
import { SurveyStep } from "./steps/SurveyStep"

interface HelpdeskSurveyStepsProps {
    step: number
    goNext: () => void
}

const HelpdeskSurveySteps: React.FC<HelpdeskSurveyStepsProps> = ({ step, goNext }) => {
    switch (step) {
        case 0:
            return <IntroductionStep onClick={goNext} />
        case 1:
            return <SurveyStep onClick={goNext} />
        case 2:
            return <ConclusionStep />
        default:
            return null
    }
}

export { HelpdeskSurveySteps }
