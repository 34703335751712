import { CUSTOMER_FEEDBACK_API_ROOT } from "modules/common"
import { useMutation } from "react-query"
import { IAnswer, IAnswerResponse } from "./types"

async function createAnswer(surveyId: string, answer: IAnswer): Promise<IAnswerResponse> {
    if (!CUSTOMER_FEEDBACK_API_ROOT) {
        throw Error(`Missing environment variable 'CUSTOMER_FEEDBACK_API_ROOT'`)
    }

    const response = await fetch(`${CUSTOMER_FEEDBACK_API_ROOT}/surveys/${surveyId}/answers`, {
        method: "POST",
        body: JSON.stringify(answer),
        headers: {
            "Content-Type": "application/json",
        },
    })

    if (!response.ok) {
        const errorText = await response.text()
        throw new Error(`Failed sending feedback to customer-feedback service: ${errorText}`)
    }

    return response.json()
}

export const useCreateAnswer = () => {
    const mutation = useMutation(
        ({
            surveyId,
            score,
            comment,
            name,
            email,
        }: {
            surveyId: string
            score: IAnswer["score"]
            comment: IAnswer["comment"]
            name: IAnswer["name"]
            email: IAnswer["email"]
        }) => {
            const answer: IAnswer = {
                score,
                comment,
                name,
                email,
            }

            return createAnswer(surveyId, answer)
        }
    )

    return {
        create: mutation.mutate,
        createAsync: mutation.mutateAsync,
        isLoading: mutation.isLoading,
        isError: mutation.isError,
    }
}
