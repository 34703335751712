import defaultsOrg from "../defaults"

const primaryColorRgb = "41, 90, 204"

const styles = (defaults: typeof defaultsOrg) => ({
    viewport: {
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        bottom: 0,
        right: 0,
        maxWidth: 400,
        margin: 24,
        padding: 0,
        zIndex: defaults.zIndex.Survey,
        listStyle: "none",
    },
    root: {
        '&[data-state="open"]': {
            animation: "$fadeIn 250ms ease-in-out forwards, $shakeEvery30s 30s infinite paused",
        },
        '&[data-state="closed"]': {
            animation: "$fadeOut 250ms ease-in-out forwards",
        },
        '&[data-attention-animation="shake"][data-state="open"][data-paused="false"]': {
            animationPlayState: "running",
        },
    },
    pulseDot: {
        backgroundColor: defaults.colors.primary[500],
        width: 8,
        height: 8,
        borderRadius: defaults.cornerRadius.rounded,
        boxShadow: `0 0 0 0 rgba(${primaryColorRgb}, 1)`,
        transform: "scale(1)",
        animation: "$pulse 2s infinite",
    },
    "@keyframes pulse": {
        "0%": {
            transform: "scale(0.95)",
            boxShadow: `0 0 0 0 rgba(${primaryColorRgb}, 0.7)`,
        },
        "70%": {
            transform: "scale(1)",
            boxShadow: `0 0 0 6px rgba(${primaryColorRgb}, 0)`,
        },
        "100%": {
            transform: "scale(0.95)",
            boxShadow: `0 0 0 0 rgba(${primaryColorRgb}, 0)`,
        },
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
    "@keyframes fadeOut": {
        "0%": {
            opacity: 1,
        },
        "100%": {
            opacity: 0,
        },
    },
    "@keyframes shakeEvery30s": {
        "0%": { transform: "translateY(0)" },
        "97.00%": { transform: "translateY(0)" },
        "97.50%": { transform: "translateY(-20px)" },
        "98.00%": { transform: "translateY(0)" },
        "98.50%": { transform: "translateY(-10px)" },
        "99.00%": { transform: "translateY(0px)" },
        "99.50%": { transform: "translateY(-5px)" },
        "100%": { transform: "translateY(0)" },
    },
})

export default styles
