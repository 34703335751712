import {
    BoxEdges,
    Button,
    FileTextIcon,
    Grid,
    Loader,
    Paragraph,
    Separator,
    Spacer,
    Survey,
    Textarea,
    ThemeContext,
    ToggleGroup,
} from "@clearhaus/design-system"
import { HELPDESK_SURVEY_ID } from "modules/customer-feedback/constants"
import { useCreateAnswer } from "modules/customer-feedback/queries"
import { SurveySubmitted } from "modules/insights/Events/SurveySubmitted"
import { MeContext } from "modules/me/Provider/context"
import React, { useContext, useMemo } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"

interface ISurveyStepProps {
    onClick?: () => void
}

export const SurveyStep: React.FC<ISurveyStepProps> = (props) => {
    const { onClick } = props

    const { defaults } = useContext(ThemeContext)

    const { translate } = useContext(LocalizeContext)
    const { data: me } = useContext(MeContext)

    const texts = useMemo(
        () => ({
            commentPlaceholder: translate(
                "surveys.helpdesk_survey.survey.comment_placeholder"
            ).toString(),
        }),
        []
    )
    const { createAsync: create, isLoading } = useCreateAnswer()

    const [score, setScore] = React.useState<number | undefined>(undefined)
    const [comment, setComment] = React.useState<string | undefined>(undefined)

    const isSubmitDisabled = !score || !comment

    const onScoreChange = (value: string) => {
        const intValue = Number(value)

        setScore(intValue)
    }

    const onCommentChange = (_e: any, value: string) => {
        setComment(value)
    }

    const submitAnswer = async () => {
        const surveyId = HELPDESK_SURVEY_ID
        const email = me?.email ?? "unknown"
        const name = me?.name ?? "unknown"

        if (!surveyId || !score || !comment) {
            onClick?.()

            throw new Error("Helpdesk survey has missing required fields")
        }

        SurveySubmitted.log({ id: surveyId })

        await create({
            surveyId,
            score,
            comment,
            email,
            name,
        })

        onClick?.()
    }

    return (
        <>
            <Grid row alignContent="center" noGutters>
                <Grid item auto>
                    <BoxEdges mr={10}>
                        <Spacer size={4} />
                        <FileTextIcon size={16} color={defaults.colors.primary[500]} />
                    </BoxEdges>
                </Grid>
                <Grid item>
                    <Survey.Title>
                        <Translate id="surveys.helpdesk_survey.survey.title" />
                    </Survey.Title>
                </Grid>
            </Grid>
            <BoxEdges my={12}>
                <Separator />
            </BoxEdges>
            <Survey.Body>
                <Paragraph>
                    <Translate id="surveys.helpdesk_survey.survey.rating_label" />
                </Paragraph>
                <Spacer size={14} />
                <ToggleGroup onValueChange={onScoreChange}>
                    <ToggleGroup.Item value="1">1</ToggleGroup.Item>
                    <ToggleGroup.Item value="2">2</ToggleGroup.Item>
                    <ToggleGroup.Item value="3">3</ToggleGroup.Item>
                    <ToggleGroup.Item value="4">4</ToggleGroup.Item>
                    <ToggleGroup.Item value="5">5</ToggleGroup.Item>
                </ToggleGroup>
                <Spacer size={20} />
                <Paragraph>
                    <Translate id="surveys.helpdesk_survey.survey.comment_label" />
                </Paragraph>
                <Spacer size={14} />
                <Textarea onChange={onCommentChange} placeholder={texts.commentPlaceholder} />
                <Spacer size={24} />
                <Grid row noGutters>
                    <Grid item auto>
                        <Survey.Close>
                            <Button color="primary" light size="normal">
                                <Translate id="surveys.helpdesk_survey.survey.close_button" />
                            </Button>
                        </Survey.Close>
                    </Grid>
                    <Spacer size={12} />
                    <Grid item>
                        <Button
                            prefix={
                                isLoading ? <Loader size={14} fillToParent noBackdrop /> : undefined
                            }
                            onClick={submitAnswer}
                            disabled={isSubmitDisabled || isLoading}
                            size="normal"
                        >
                            <Translate id="surveys.helpdesk_survey.survey.next_button" />
                        </Button>
                    </Grid>
                </Grid>
            </Survey.Body>
        </>
    )
}
